import React from 'react';

export const StoryIcon = ({ stroke = '#044A97' }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15 6.23529V26" stroke={stroke} strokeMiterlimit="10" />
    <path
      d="M15 6.23529C9.90909 5 8.63636 5 1 5V24.7647C8.44545 24.8265 11.1818 24.7647 15 26C18.8182 24.7647 21.3636 24.7647 29 24.7647V5C21.3636 5 20.0909 5 15 6.23529Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.81641 11.0282H11.18"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.81641 14.7341H11.18"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.81641 18.44H11.18"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.8184 11.1765H25.182"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.8184 14.8824H25.182"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.8184 18.5882H25.182"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
